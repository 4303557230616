.upload-container {
   
    margin: 1rem;
    padding: 1.5rem ;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  
    // @media (min-width: 768px) {
    //   padding: 4rem 0;
    // }
  
    // @media (min-width: 1024px) {
    //   padding: 1.5rem 1rem;
    // }
  }
  
  .upload-content {
    display: grid;
    gap: 2rem;
  }
  
  .upload-header {
    display: grid;
    gap: 1rem;
  }
  
  .upload-title {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.025em;
  
    @media (min-width: 640px) {
      font-size: 1.875rem;
    }
  }
  
  .upload-description {
    color: #737373; // muted-foreground
  }
  
  .upload-grid {
    display: grid;
    gap: 1.5rem;
  
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .upload-field {
    display: grid;
    gap: 0.5rem;
  }
  
  .upload-label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
  }
  
  .upload-input-group {
    display: flex;
    gap: 0.5rem;
  }
  
  .upload-input {
    height: 2.5rem;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e5e5; // border color
    background-color: #ffffff; // background color
    color: #282828; // foreground color
    outline: none;
  
    &:focus-visible {
      border-color: #ff4d4d; // ring color
      box-shadow: 0 0 0 2px #ff4d4d; // ring color
    }
  
    &::file-selector-button {
      border: none;
      background: transparent;
      font-size: 0.875rem;
      font-weight: 500;
      color: #282828; // foreground color
      padding: 0;
    }
  }
  
  .upload-button {

    padding: 0 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #ffefff; // primary-foreground
    background-color:#013917; // primary color
    border-radius: 0.5rem;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: rgba(7, 83, 0, 0.9); // hover color for primary
    }
  
    &:focus-visible {
      box-shadow: 0 0 0 2px #004e08; // ring color
    }
  }
  