.qes-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 3rem 1rem;
}

.content {
  max-width: 28rem;
  width: 100%;
  margin-top: 5rem;
  .card {
    background-color: #ffffff; /* bg-card */
    border: 1px solid #dee2e6; /* border-border */
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-top: 1rem;
    .text-center {
      text-align: center;
      .question {
        font-size: 1.5rem; /* 2xl */
        font-weight: bold;
        color: #212529; /* text-foreground */
      }
    }
    .fakeanswer-label {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: medium;
      color: #3a3c3e;
      margin-top: 1.5rem;
    }
    .input {
      height: 2.5rem;
      width: 93%;
      padding: 0 0.75rem;
      font-size: 0.875rem;
      border: 1px solid var(--primary);
      background-color: var(--background);
      color: var(--foreground);
      border-radius: 0.375rem;
      transition: border-color 0.2s, box-shadow 0.2s;
    }
  }
}

.title {
  font-size: 1.875rem; /* 3xl */
  font-weight: bold;
  color: #212529; /* text-foreground */
}

.subtitle {
  margin-top: 0.5rem;
  color: #6c757d; /* text-muted-foreground */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  .timer {
    color: #6c757d; /* text-muted-foreground */

    .timer-sec {
      color: var(--primary); /* text-muted-foreground */
    }
  }
  .button {
    background-color: var(--primary);
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    .button:hover {
      background-color: #025022;
    }
    &:disabled {
      background-color: #888888; /* Disabled background color */
      cursor: not-allowed;
    }
  }
}

