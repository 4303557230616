.feedback-form {
  // width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  padding: 3rem 1rem;

  .feedback-header {
    text-align: center;
    margin-bottom: 2rem;

    .feedback-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .feedback-subtitle {
      color: #6c757d;
      font-size: 1.125rem;
    }
  }

  .feedback-content {
    margin-top: 2.5rem;

    .accordion {
      border-bottom: 1px solid #ccc;

      .accordion-section {
        transition: max-height 0.2s ease-out;

        &.closed {
          .accordion-body {
            max-height: 0;
          }
        }

        &.open {
          .accordion-body {
            max-height: 100px;
          }
        }

        .accordion-header {
          display: flex;
        }

        .accordion-toggle {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 0;
          transition: all 0.2s;
          font-size: 1rem;
          font-weight: 500;
          text-align: left;
          background: none;
          border: none;
          width: 100%;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          .accordion-icon {
            transition: transform 0.2s;

            &.rotate-180 {
              transform: rotate(180deg);
            }
          }
        }

        .accordion-body {
          overflow: hidden;
          transition: max-height 0.2s ease-out;
          // padding: 1rem 0;
          border: 1px solid #ccc;
          border-radius: 0.375rem;
          padding: 0.75rem;
          margin-block: 10px;
        }

        .accordion-text {
          padding: 1rem 0;
        }
       .correct-ans {
          color: #3c8f00;
        }
        .input-btn {
          display: flex;
          padding: 1rem 0;
          color: black;
          .button {
            padding: 0.5rem 1rem;
            border-radius: 0.25rem;
            font-size: 1rem;
            cursor: pointer;
            border: none;
            margin-left: 0.8rem;
            transition: background-color 0.2s ease, color 0.2s ease;
          }
        }
      }
    }

    .feedback-additional {
      display: grid;
      gap: 0.5rem;
      margin-top: 1.5rem;

      .feedback-label {
        font-size: 0.875rem;
        font-weight: 500;
        color: #333;
      }

      .feedback-textarea {
        border: 1px solid #ccc;
        border-radius: 0.375rem;
        padding: 0.75rem;
        font-size: 0.875rem;
        min-height: 150px;
        resize: vertical;
        transition: border-color 0.2s;

        &:focus-visible {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &::placeholder {
          color: #6c757d;
        }
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;

    @media (min-width: 640px) {
      // sm: breakpoint
      flex-direction: row;
      justify-content: space-between; // Equivalent to Tailwind's sm:justify-between
    }
  }

  .button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgb(9, 103, 0); // Example focus ring
    }

    &.button-outline {
      background-color: transparent;
      border: 1px solid #ccc; // Example border color
      color: var(--primary); // Example text color
    }

    &.button-primary {
      background-color: var(--primary); // Example primary color
      color: #fff; // Example text color
    }

    &:hover {
      background-color: darken(
        #3c8f00,
        10%
      ); // Darkens the primary color for hover effect
    }

    &.button-outline:hover {
      background-color: rgba(
        0,
        0,
        0,
        0.05
      ); // Example hover effect for outline button
    }
  }
}
