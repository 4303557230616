// Define color variables
$background-color: #f9fafb;
$foreground-color: #1f2937;
$muted-foreground-color: #6b7280;
$primary-color: #3b82f6;
$primary-foreground-color: #ffffff;
$input-border-color: #d1d5db;
$muted-background-color: #f3f4f6;
$border-color: #e5e7eb;

// Define breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

// Mixin for breakpoints
@mixin breakpoint($size) {
  @if $size == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  }
}

.signin-container {
  display: flex;
  height: 100%;
  height: calc(100vh - 6.5rem);
  align-items: center;
  justify-content: center;
  background-color: $background-color;
  padding-inline:  2rem;

  @include breakpoint(sm) {
    padding-inline:  2rem;
  }
  @include breakpoint(lg) {
    padding-inline:  2rem;
  }
}
b{
  color: var(--primary);
}
.signin-wrapper {
  width: 100%;
  max-width: 400px;


  .signin-header {
    text-align: center;

    .signin-title {
      margin-top: 1.5rem;
      font-size: 1.875rem;
      font-weight: bold;
      color: $foreground-color;
      letter-spacing: -0.025em;

      @include breakpoint(md) {
        font-size: 2.25rem;
      }
    }

    .signin-subtitle {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      color: $muted-foreground-color;

      .signin-link {
        font-weight: bold;
        color: var(--primary);
        transition: color 0.2s;
        text-decoration: none;

        &:hover {
          color: lighten($primary-color, 10%);
        }
      }
    }
  }

  .signin-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include breakpoint(md) {
      gap: 1.25rem;
    }
  }

  .signin-input-group {
    position: relative;
    .signin-label {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: medium;
      color: $foreground-color;
    }
    .signin-input {
      height: 2.5rem;
      width: 93%; 
      padding: 0 0.75rem;
      font-size: 0.875rem;
      border: 1px solid var(--primary);
      background-color: $background-color;
      color: $foreground-color;
      border-radius: 0.375rem;
      transition: border-color 0.2s, box-shadow 0.2s;


      &:focus {
        border-color: $primary-color;
        outline: none;
        box-shadow: 0 0 0 2px $primary-color;
      }

      &::placeholder {
        color: $muted-foreground-color;
      }
    }
  }

  .signin-button {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: medium;
    background-color: var(--primary);
    color: $primary-foreground-color;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
    margin-bottom: 2rem;

    &:hover {
      background-color: lighten($primary-color, 10%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $primary-color;
    }

    @include breakpoint(md) {
      font-size: 1rem;
    }
  }

  .signin-divider {
    position: relative;
    margin: 2rem 0;
    text-align: center;
    font-size: 0.875rem;
    color: $muted-foreground-color;

    .divider-line {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px solid $border-color;
    }

    .divider-text {
      display: inline-block;
      position: relative;
      background-color: $background-color;
      padding: 0 0.5rem;
      z-index: 1;
    }
  }

  .guest-button {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: medium;
    color: $muted-foreground-color;
    background-color: $background-color;
    border: 1px solid $input-border-color;
    border-radius: 0.375rem;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background-color: $muted-background-color;
      color: $muted-foreground-color;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $muted-background-color;
    }

    @include breakpoint(md) {
      font-size: 1rem;
    }
  }
}
