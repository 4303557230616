.feedback-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 1.5rem 1rem;
    margin: 16px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  
    .header {
      margin-bottom: 1.5rem;
  
      h2 {
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
      }
  
      .text-muted {
        color: hsl(var(--muted-foreground));
      }
    }
  
    .feedback-table-wrapper {
      border: 1px solid rgb(229, 229, 229);
      border-radius: 0.5rem;
      overflow: hidden;
      width: 100%;
  
      .feedback-table {
        width: 100%;
        text-align: left;
        font-size: 0.875rem;
        font-family: 'Inter', sans-serif;

  
        th,
        td {
          padding: 1rem;
        }
  
        th {
          font-weight: 500;
          color: rgb(151, 151, 151);
          border-bottom: 1px solid  rgb(229, 229, 229);
  
          &.hidden-sm {
            @media (max-width: 640px) {
              display: none;
            }
          }
        }
  
        td {
          border-bottom: 1px solid hsl(var(--border));
  
          &.hidden-sm {
            @media (max-width: 640px) {
              display: none;
            }
          }
        }
  
        tbody {
          tr:hover {
            background-color: hsl(var(--muted) / 0.5);
          }
        }
      }
  
      .feedback-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
  
        .text-muted-sm {
          color: hsl(var(--muted-foreground));
          font-size: 0.875rem;
  
          @media (min-width: 640px) {
            margin-left: auto;
          }
        }
      }
  
      .action-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
      
        .status-indicator {
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
          background-color: rgb(215, 0, 0);
        }
      
        .mark-read-btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid rgb(175, 175, 175);
          background-color: #013917;
          color: white;
          border-radius: 0.5rem;
          transition: background-color 0.3s ease, color 0.3s ease;
      
          &.disabled {
            background-color: #ddd;
            color: #999;
            cursor: not-allowed;
          }
        }
      
      }
      
    }
  }
  .view-feedback-btn{
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid rgb(175, 175, 175);
    background-color: #013917;
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;

  }
  .delete-feedback-btn{
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid rgb(175, 175, 175);
    background-color: #a80000;
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;

  }