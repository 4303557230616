/* Container for the whole app */
@import "adminstyle/mixin";
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
  
  /* Header styling */
  .header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  /* User menu styling */
  .user-menu {
    margin-left: auto;
  }
  
  .avatar-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  /* Main content styling */
  .main {
    flex: 1;
    padding: 16px;
  }
  
  .card-container {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
  }
  
  .card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .card-header {
    padding: 24px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .card-subtitle {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .card-body {
    padding: 24px;
  }
  
  .form {
    display: grid;
    gap: 16px;
  }
  
  .admin-form-group {
    display: grid;
    gap: 8px;
  }
  
  .form-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .form-textarea{
    min-height: 80px;
    resize: vertical;
  }
  .form-textarea,
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .form-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: var( --primary);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: hsl(347, 74%, 55%);
  }
  
  .search-container {
    display: flex;
    margin-bottom: 16px;
  }
  
  .search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .table-container {
    width: 100%;
    overflow: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header {
    padding: 12px 16px;
    text-align: left;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .table-row {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .table-cell {
    padding: 12px 16px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .pagination-nav {
    display: flex;
    justify-content: center;
  }
  
  .pagination-list {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 8px;
  }
  
  .pagination-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: var(--primary);
    color: #f1f1f1;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    text-decoration: none;

  }
  
  .pagination-button:hover {
    background-color:  hsl(347, 74%, 56%);;
  }
  .edit-button, .delete-button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #4caf50;
    color: white;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
  }
  
  .edit-button:hover {
    background-color: #45a049;
  }
  
  .delete-button:hover {
    background-color: #d32f2f;
  }
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-info {
    font-size: 0.875rem;
    padding: 8px 10px;
  }

  /* FullScreenLoader.css */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.admin-container {
  @include grid(1fr 4fr, 2rem);
  height: 100%;
  background-color: rgb(247, 247, 247);
  > aside {
    width: 100%;
    background-color: white;
    padding: 1rem;
    z-index: 10;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      margin: 2rem 1rem;
      > h5 {
        @include heading(2px);
        opacity: 0.8;
        margin: 1rem 0;
      }
      > ul {
        @include flex(column, unset, unset, 0.5rem);
        list-style: none;
        padding: 0 !important;
        > li {
          padding: 0.5rem 1rem;
          border-radius: 10px;
          a {
            color: rgba(0, 0, 0, 0.825);
            text-decoration: none;
            @include flex(row, unset);
          }
        }
      }
    }
  }
}
#hamburger {
  display: grid;
  place-items: center;
  @include square(3rem);
  border: none;
  outline: none;
  cursor: pointer;
  color: #013917;
  position: fixed;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  background-color: white;
  border-radius: 50%;
  z-index: 9;
}
#close-sidebar {
  width: 80%;
  margin: 1rem auto;
  display: block;
  padding: 0.75rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgb(168, 2, 2);
  color: white;
  border-radius: 10px;
}
