.home-page {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  background-color: var(--background);
}

.home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 1rem;
  text-align: center;

  &__text {
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.025em;
  }

  &__description {
    max-width: 700px;
    color: var(--muted-foreground);
    font-size: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 640px) {
      flex-direction: row;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: none;
  outline: none;

  &--create {
    background-color: var(--primary);
    color: var(--primary-foreground);

    &:hover {
      background-color: #025122;
    }

    &:focus-visible {
      outline: 1px solid hsl(126, 77%, 50%);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &--join {
    background-color: var(--background);
    color: #09090b;
    border: 1px solid var(--input);

    &:hover {
      background-color: var(--accent);
      color: var(--accent-foreground);
    }

    &:focus-visible {
      outline: 2px solid var(--ring);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.home-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; // Creates space between flex items
  padding: 4rem 1rem;
  text-align: center;




  &__title {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--secondary-foreground);
  }

  &__description {
    max-width: 700px;
    color: var(--muted-foreground);
    font-size: 1.25rem;
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    max-width: 28rem;
    width: 100%;
  }
}


.step {
  display: flex;
  align-items: start;
  gap: 1rem;
  align-items: center;

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--primary-foreground);
    font-weight: bold;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__description {
    color: var(--muted-foreground);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    padding: 20px;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 375px;
    margin-top: 20px;
    position: relative;
    margin-inline: 1rem;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(25, 25, 25);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
