body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert {
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  
  &.alert-destructive {
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    color: #991b1b;
  }

  .alert-title {
    margin-left: 0.5rem;
    font-weight: 500;
  }
}