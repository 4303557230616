/* Leaderboard.scss */

$main-text-color: white;
$accent-color: #ff5722;

.leaderboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--primary);
  color: $main-text-color;
  padding: 1rem 1rem 0 1rem;
}

// .leaderboard-header {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   margin-bottom: 1rem;
// }
.leaderboard-header {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  // padding: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: 0.75rem;
  margin-block: 10px;
  background-color: #fff;
  color: black;
  display: flex;
  align-items: center;
  .accordion-text {
    padding: 1rem 0;
  }
  .correct-ans {
    color: #3c8f00;
  }
}

.info-button {
  margin-left: 10px;
  padding: 5px;
  background-color: #a2a2a2;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-button:hover {
  background-color: #a4a5a5;
}
.reference-info {
  position: absolute;
  top: 145px;
  left: 55%;
  transform: translateX(-50%);
  background-color: rgb(217, 237, 215);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  color: black;
}
.reference-info::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 72%;
  transform: rotate(180deg);
  border-width: 10px;
  border-style: solid;
  border-color: rgb(217, 237, 215) transparent transparent transparent; /* Top arrow */
}
.reference-info.animated {
  opacity: 1;
  visibility: visible;
  animation: float 0.5s ease-in-out forwards;
}

@keyframes float {
  0% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
.leaderboard-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.leaderboard-title {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.leaderboard-avatar {
  position: relative;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  background-color: var(--background);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.leaderboard-player-name {
  font-size: 1.125rem;
  font-weight: 600;
}

.leaderboard-score {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.leaderboard-list {
  width: 100%;
  padding: 1rem;
  background-color: white;
  border-radius: 26px 26px 0 0;
  color: var(--secondary-foreground);
  height: 100%;

  .leaderboard-list-title {
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    font-weight: 800;
    color: var(--primary);
  }
  .btn-cnt {
    display: flex;
    justify-content: center;
    .button {
      background-color: var(--primary);
      color: #ffffff;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      cursor: pointer;
      font-size: 18px;
    }
  }
  .leaderboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    background-color: rgb(217, 237, 215);
    margin-bottom: 10px;
    border-radius: 12px;
    &:last-child {
      border-bottom: none;
    }
    .leaderboard-side {
      display: flex;
      align-items: center;
      .leaderboard-item-cnt {
        display: flex;
        flex-direction: column;
        .leaderboard-item-name {
          font-size: 1rem;
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden; /* Hide the overflowed content */
          text-overflow: ellipsis;
          font-weight: 550;
        }
        .leaderboard-item-answer {
          font-size: 0.7rem;
          color: rgb(54, 147, 29) !important;
        }
      }
    }

    .leaderboard-rank {
      margin-right: 0.5rem;
      font-size: 1rem;
      padding: 2px;
      font-weight: 600;
      height: 25px;
      width: 25px;
      background-color: var(--primary);
      border-radius: 50%;
      align-items: center;
      color: var(--background);
      display: flex;
      justify-content: center;
    }

    .leaderboard-item-avatar {
      position: relative;
      display: flex;
      height: 2.5rem;
      width: 2.5rem;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 0.5rem;
      background-color: var(--background);

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .leaderboard-item-score {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      font-weight: 600;
      color: $accent-color;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

// Leaderboard.scss
.leaderboard {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 16px;
  width: 100%;
  max-width: 1000px;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
    position: relative;
    min-width: 50px;
    width: 100%;
  }
}

.card-large {
}
.card-small {
}
.avatar-container {
  position: relative;
}

.avatars {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 2px solid #00b9e2;
}

.avatar-large {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border: 2px solid #f9a826;
}

.star-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  padding: 5px;
  height: 1.5rem;
  background-color: #f9a826;
  border-radius: 50%;
}

.badge {
  position: absolute;
  bottom: 0;
  right: 10%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}

.secondary-badge {
  background-color: #00b9e2;
  color: #fafafa;
  border: 1px solid transparent;
}

.primary-badge {
  background-color: #f9a826;
  color: #ffffff;
  border: 1px solid transparent;
}

.name {
  margin-top: 0.5rem;
  color: #888888;
  font-size: 12px;
}

.primary-score {
  color: #f9a826;
  font-size: 1.5rem;
  font-weight: bold;
}
.score {
  color: #00b9e2;
  font-size: 1.5rem;
  font-weight: bold;
}
