.game-settings {
    border-radius: 8px;
    background-color: #ffffff;
    color: #0a0a0a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    margin: 1rem;
    padding: 1.5rem 1rem;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  
    &__header {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
    &__title {
      font-size: 1.75rem;
      font-weight: 600;
    }
  
    &__description {
      font-size: 0.875rem;
      color: #6d6d6d;
    }
  
    &__body {
      padding: 1.5rem;
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 1fr;
  
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  
    &__input-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
    &__label {
      font-size: 0.875rem;
      font-weight: 500;
    }
  
    &__input {
      height: 2.5rem;
      padding: 0.5rem 0.75rem;
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      background-color: #ffffff;
      font-size: 0.875rem;
      outline: none;
      transition: border-color 0.3s ease;
  
      &:focus-visible {
        border-color: #ff5b79;
        box-shadow: 0 0 0 2px #ff5b79;
      }
    }
  
    &__help-text {
      font-size: 0.875rem;
      color: #6d6d6d;
    }
  }