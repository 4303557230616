.dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    height: 100%;
  }
  .button-outline:hover {
    background-color: #f0f0f0;
  }
  
  /* .dialog-content {
    display: none;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 100%;
    max-width: 425px;
    margin-top: 20px;
  } */
  
  .dialog-header {
    margin-bottom: 20px;
  }
  .question-heading{
    color:var(--muted-foreground);
  }
  
  .dialog-header h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
  
  .form-group {
    display: flex;
   align-items: center;
    margin-bottom: 20px;
  }
  
  .form-group label {
    margin-bottom: 8px;
    font-weight: 500;
    margin-right: auto;
  }
  
 
  
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .button {
    background-color:  var(--primary);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    /* width: 100%; */
 
  }
  .css-ltlhnc-MuiSlider-root {

    color: var(--primary) !important;

  }
.MuiFormControl-root ,.MuiTextField-root, .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 60%;
    margin-left: auto;
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    
    margin-left: auto;
  }
 
  .button:hover {
    background-color:  hsl(347, 96%, 42%);
  }
  