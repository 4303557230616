nav{
    padding: 1.5rem 1rem;
    height: 2.2rem;
    background-color: var(--primary);
    display: flex;
    justify-content: space-between;
    .side-cnt{
        gap: 10px;
        display: flex;
        .select-lang{
         padding:8px;
         color:var(--primary) ;
         border-radius: 5px;
        }
        button{
            border-radius: 5px;
            background-color: transparent;
            border: none;
            color: white;
        }
    }
    h2{
        text-align: center;
        color: var(--secondary);
        letter-spacing: 0.15em;
    }
}