.user-list__container {
    padding: 1.5rem;

    margin: 16px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .user-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .user-list__title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .user-list__controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user-list__search {
    height: 2.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    padding: 0 1rem;
    background-color: white;
    color: #333;
  }
  
  .user-list__roles-btn {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    padding: 0 1rem;
    background-color: white;
    color: #333;
    cursor: pointer;
  }
  
  .user-list__icon-chevron {
    width: 1rem;
    height: 1rem;
    opacity: 0.5;
  }
  
  .user-list__table-wrapper {
    overflow-x: auto;
  }
  
  .user-list__table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  
    th, td {
      padding: 1rem;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  
  .user-list__actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .user-list__edit-btn {
    border: 1px solid #e0e0e0;
    background-color: white;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  
    &:hover {
      background-color: #f5f5f5;
      color: #000;
    }
  }
  
  .user-list__delete-btn {
    background-color: #f56565;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #e53e3e;
    }
  }
  