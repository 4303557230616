/* Base styles */
:root {
  --background: hsl(0, 0%, 100%);
  --foreground: hsl(240, 10%, 3.9%);
  /* --primary: hsl(346.8, 77.2%, 49.8%); */
  --primary-foreground: hsl(355.7, 100%, 99%);
  --secondary: hsl(240, 4.8%, 95.9%);
  --secondary-foreground: hsl(240, 5.9%, 10%);
  --muted: hsl(240, 4.8%, 95.9%);
  --muted-foreground: hsl(240, 3.8%, 45%);
  --accent: hsl(240, 4.8%, 95.9%);
  --accent-foreground: hsl(240, 5.9%, 10%);
  --destructive: hsl(0, 72%, 51%);
  --destructive-foreground: hsl(0, 0%, 98%);
  --border: hsl(240, 5.9%, 90%);
  --input: hsl(240, 5.9%, 90%);
  --ring: hsl(346.8, 77.2%, 49.8%);
  --radius: 0.5rem;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  font-family: 'Inter', sans-serif;
}

/* Container styles */
.room-container{
  display: flex;
  height: 100%;
  .room-containers {
    max-width: 1024px !important;
    width: 100%;
    margin: 0 auto;
    padding: 24px 1.5rem;
   justify-content: center;
    display: flex;
    .room-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      .room-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .room-id{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .room-details-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 6px;
          margin-top: 10px;
          border-radius: 6px;
          background-color: #bae4b3;
          .room-title {
            font-size: 0.800; // equivalent to text-lg
            font-weight: 600; // equivalent to font-medium
          }
          
          .room-info {
            font-size: 0.875rem; // equivalent to text-sm
            color: #6c757d; // Use a muted color for text-muted-foreground
          }
        }
      } 
    }
  }
}


.start-button {
  background-color:  var(--primary);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;

}
.toggle-button {
  background-color:  var(--primary);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;

}
.leave-button {
  background-color:  rgb(167, 0, 0);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;

}

.copy-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid var(--primary);
  background-color: var(--background);
  color: var(--primary);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
}

.copy-button:hover {
  background-color: var(--accent);
  color: var(--accent-foreground);
}
svg{
  font-size: 16px;

}
/* Players grid */
.players-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 16px;
}

/* Player card */
.player-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 5px;
}
.player-info {
  display: flex;
  width:100%;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  // margin-right:  auto;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}
.player-card.host {
  border: 2px solid #00a441; // or any color to highlight the host
}

.host-label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #00a441;
  color: rgb(252, 252, 252);
  padding: 2px 5px;
  border-radius: 0 0 0 5px;
  font-size: 0.75rem;
  font-weight: bold;
}
.isHost {
  background-color: var(--primary);
  color: var(--primary-foreground);
}

.players {
  background-color: var(--muted);
  color: var(--muted-foreground);
}

.player-name {
  font-weight: 500;
  max-width: 120px; 
  white-space: nowrap; 
  overflow: hidden;  /* Hide the overflowed content */
  text-overflow: ellipsis;  /* Display ellipsis (...) when the text overflows */
}
.options-menu {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #9a9a9a;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 5px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #320404;
  font-size: smaller;
}

.player-info-cnt{
  display: flex;
  gap: 5px;
}
.dropdown-item:hover {
  background-color: #f5f5f5;
}

.player-status {
  font-size: 0.875rem;
  color: var(--muted-foreground);
}

/* Status button */
.status-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-block: 8px ;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;
  /* cursor: pointer;
  transition: background-color 0.3s, color 0.3s; */
}

.ready {
  color: #22c55e;
}

.not-ready {
  color: red;
}
.ready-badge{
  font-size: 0.875rem;
}
/* 
.status-button:hover {
  background-color: var(--accent);
  color: var(--accent-foreground);
} */
@media only screen and (max-width: 600px) {
  .room-containers {

    padding: 24px 12px;
  }
}